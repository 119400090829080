import React, { useEffect } from 'react';
import { useAuthentication } from '@hooks/authentication';
import { useRouter } from 'next/router';

const Index = () => {
  const {
    isAuthenticated,
  } = useAuthentication();
  const { push } = useRouter();

  useEffect(() => {
    if (isAuthenticated) {
      push('/dashboard');
    } else {
      push('/login');
    }
  }, []);

  return <></>;
};

export default Index;
